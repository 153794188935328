import React from 'react'

import { Link } from 'react-router-dom';
import hd2 from "../../assets/hdImage/hd2.jpg"
import hd3 from "../../assets/hdImage/hd3.jpg"
import Img from '../lazyload/Img';
const HomeSection2 = () => {
  return (
  
    <div className='w-11/12 lg:w-3/4 mx-auto mt-16'>
  <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
    <div className='bg-white h-[100%] rounded-lg shadow-md overflow-hidden'>
      <Img className='w-full  h-96  object-cover' src={hd3} alt="Product Overview 1" />
      <div className='p-6'>
        <h1 className='text-xl font-bold text-gray-900 mb-2'>About Us</h1>
        <p className='text-gray-700 mb-4'>
        Established in 2000 and headquartered in Howrah, West Bengal, India, MDKF is a premier crusher plant manufacturing company.</p>
        <Link to="/about" className='text-orange-500 font-semibold hover:underline'>Know More</Link>
      </div>
    </div>
    <div className='bg-white h-[100%] rounded-lg shadow-md overflow-hidden'>
      <Img className='w-full  h-96 object-cover' src={hd2} alt="Product Overview 2" />
      <div className='p-6'>
        <h1 className='text-xl font-bold text-gray-900 mb-2'>Gallery</h1>
        <p className='text-gray-700 mb-4'>
        MDKF's gallery showcases our diverse range of products and installations. From jaw crushers to screening plants...</p>
        <Link to="/gallery" className='text-orange-500 font-semibold hover:underline'>Know More</Link>
      </div>
    </div>
  </div>
</div>




  )
}

export default HomeSection2

