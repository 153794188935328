import { Link } from 'react-router-dom';
import Img from '../lazyload/Img';

const ProductCard = ({ title, description, image, link }) => {
  return (
    <div className="max-w-sm h-full rounded overflow-hidden shadow-lg pb-4">
      {/* Product image */}
      <Img className="w-full  object-cover" src={image} alt={title} />

      {/* Product details */}
      <div className="px-6 py-4 h-full flex flex-col justify-between pb-10">
        <div>
          <div className="font-bold text-xl mb-2">{title}</div>
          <p className="text-gray-700 text-base overflow-hidden line-clamp-3">{description}
         
          </p>
          <Link to={link} className="text-orange-500 hover:text-orange-700 font-bold underline mt-2">
            Know More
          </Link>
        </div>

   
      </div>
    </div>
  );
};

export default ProductCard;
