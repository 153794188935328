import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import g1 from "../../assets/gallery/g1.jpg"
import g2 from "../../assets/gallery/g2.jpg"
import g3 from "../../assets/gallery/g3.jpg"
import g4 from "../../assets/gallery/g4.jpg"
import g5 from "../../assets/gallery/g5.jpg"
import g6 from "../../assets/gallery/g6.jpg"
import g8 from "../../assets/gallery/g8.jpg"
import g9 from "../../assets/gallery/g9.jpg"
import g10 from "../../assets/gallery/g10.jpg"
import g11 from "../../assets/gallery/g11.jpg"
import g12 from "../../assets/gallery/g12.jpg"
import g13 from "../../assets/gallery/g13.jpg"
import g14 from "../../assets/gallery/g14.jpg"
import g16 from "../../assets/gallery/g16.jpg"
import g17 from "../../assets/gallery/g17.jpg"
import g18 from "../../assets/gallery/g18.jpg"
import g19 from "../../assets/gallery/g19.jpg"
import g20 from "../../assets/gallery/g20.jpg"
import g21 from "../../assets/gallery/g21.jpg"
import g22 from "../../assets/gallery/g22.jpg"
import g23 from "../../assets/gallery/g23.jpg"
import g24 from "../../assets/gallery/g24.jpg"
const GallerySection = () => {

  const images = [
    { src: g1, alt: 'Image 1', caption: 'Caption 1',   width: 4,
    height: 3 },
    { src: g2, alt: 'Image 2', caption: 'Caption 2',   width: 4,
    height: 3},
    { src: g3, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g4, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g5, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g6, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    
    { src: g8, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g9, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g10, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g11, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g12, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g13, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g14, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },

    { src: g16, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g17, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g18, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g19, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g20, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g21, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g22, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g23, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },
    { src: g24, alt: 'Image 3', caption: 'Caption 3',   width: 4,
    height: 3 },

    

    // Add more image objects as needed
  ];

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  return (
    <>
    
    <div  className='w-11/12  lg:w-3/4 mx-auto' >


      <div className='flex justify-center pt-5'>
        <h1 className='text-4xl font-semibold uppercase text-gray-900 mb-8'>OUR GALLERY</h1>
      </div>
      <div>
      <Gallery photos={images} onClick={openLightbox} />
     
    </div>



    </div>
    <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
              
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

export default GallerySection
