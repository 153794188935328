import React from 'react'
import ProductsDeatils from '../../components/product/ProductsDeatils';
import vsiImage from "../../assets/Products/vsi.png";
const VerticalShaftImpactor = () => {
  const productInfo = {
    title: "Vertical Shaft Impactor",
    img:vsiImage,
    description: {
      title: "Vertical Shaft Impactor",
      para: "Elevate your crushing efficiency with our vertical shaft impactors. Designed for shaping and producing fine aggregates, our VSIs offer exceptional performance in tertiary crushing applications",
    },
  };
  return (
    <div className="w-11/12 lg:w-3/45 mx-auto">
      <ProductsDeatils info={productInfo} />
    </div>
  )
}

export default VerticalShaftImpactor
