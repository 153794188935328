import React from 'react'
import ProductsDeatils from '../../components/product/ProductsDeatils';
import jawCrushersImage from "../../assets/Products/jawCrushers.png";
const Conveyor = () => {
  const productInfo = {
    title: "Conveyor",
    img: jawCrushersImage,
    description: {
      title: "Conveyor",
      para: "Streamline material handling with our conveyor systems. From bulk material transport to precise product handling, our conveyors offer reliable operation and customizable configurations to suit your specific requirements.",
    },
  };
  return (
    <div className="w-11/12 lg:w-3/4 mx-auto">
    <ProductsDeatils info={productInfo} />
  </div>
  )
}

export default Conveyor
