import React, {useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import NavLinks from "./NavLinks";
const Navbar = () => {
  const [open, setOpen] = useState(false);

  const [navColor, setNavColor]=useState(false);
 
  
  useEffect(() => {
    const changeNavbarColor = () => {
      const isProductPage = window.location.pathname.startsWith("/product");
      if (isProductPage || window.scrollY >= 80) {
        setNavColor(true);
      } else {
        setNavColor(false);
      }
    };
  
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, [setNavColor]);
  ;




  return (
    <nav className={
      
      ` navbar fixed w-full top-0  z-50    font-bold 
       ${navColor===true? "active_navbar shadow-md" : "navabr"}
      `
    }>
      
      <div className="flex items-center font-medium justify-around">
      <div className="navbar-background grayscale-[100%] -z-30 absolute w-full py-5"></div>
        <div className="z-50 p-5  lg:w-auto w-full flex justify-between items-center">
          <img  src={Logo} alt="logo" className="md:cursor-pointer h-5  lg:h-9 logo" />
          
          <div className="text-3xl text-black lg:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>

        </div>

        <ul className="lg:flex hidden uppercase items-center gap-1 font-[Poppins] cursor-pointer">
          <li    >
            <Link 
           
             to="/" className="py-7 px-3 inline-block cursor-pointer hover:text-orange-500 transition-all">
              Home
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link to="/" className="py-7 px-3 inline-block hover:text-orange-500 transition-all">
             Application
            </Link>
          </li>

          <li>
            <Link to="/about" className="py-7 px-3 inline-block hover:text-orange-500 transition-all">
             About us
            </Link>
          </li>

          <li>
            <Link to="/gallery" className="py-7 px-3 inline-block hover:text-orange-500 transition-all">
             Gallery
            </Link>
          </li>

          
          <li>
            <Link to="/contact" className="py-7 px-3 inline-block hover:text-orange-500 transition-all">
             Contact Us
            </Link>
          </li>

        </ul>

      

        {/* Mobile nav */}
        <ul
          className={`
        lg:hidden bg-white text-black h-[100vh] overflow-y-auto fixed w-full top-0 bottom-0 pt-16 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <li>
            <Link onClick={()=>setOpen(false)} to="/" className="py-7 px-3 inline-block">
              Home
            </Link>
          </li>

          <li>
            <Link onClick={()=>setOpen(false)} to="/about" className="py-7 px-3 inline-block">
             About us
            </Link>
          </li>

          <li>
            <Link onClick={()=>setOpen(false)} to="/gallery" className="py-7 px-3 inline-block">
             Gallery
            </Link>
          </li>

          
          <li>
            <Link onClick={()=>setOpen(false)} to="/contact" className="py-7 px-3 inline-block">
             Contact Us
            </Link>
          </li>

          <NavLinks setOpen={setOpen} />
        </ul>

      </div>
    </nav>
  );
};

export default Navbar;