import React from "react";

const CompanyInfoItem = ({ title, subTitle, para }) => {
  return (
    <div>
      <div>
        <h1 className="font-bold flex gap-1">
          <span className="text-orange-500 text-4xl">{title}</span>
           <span className="text-zinc-900 text-2xl mt-2">{subTitle}</span>
        </h1>
      </div>
      <div>
        <p className="text-zinc-500 pt-1">{para}</p>
      </div>
    </div>
  );
};

export default CompanyInfoItem;
