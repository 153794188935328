import React from "react";
import jawCrusherBg from "../../assets/Products/jawCrusherBg.jpg";
import coneCrusherBg from "../../assets/Products/coneCrusherBg.jpg";
import sandPlantBg from "../../assets/Products/sandPlantBg.jpg";
import screeningBg from "../../assets/Products/screeningBg.jpg";
import visBg from "../../assets/Products/visBg.jpg";
import ProductCard from "./ProductCard";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination } from 'swiper/modules';
const AllCard = () => {
  const products = [
    {
      title: "Jaw Crushers",
      description:
        "Our jaw crushers are engineered to deliver exceptional performance in primary crushing applications. With robust construction and high crushing capacity, they ensure efficient reduction of even the toughest materials.",
      image: jawCrusherBg,
      link: "/product/jawCrushers",
    },
    {
      title: "Cone Crusher",
      description:
        "Experience superior crushing with our cone crushers. Engineered for versatility and reliability, our cone crushers excel in secondary and tertiary crushing, offering precise particle size control and exceptional throughput.",
      image: coneCrusherBg,
      link: "/product/coneCrusher",
    },
    {
      title: "Conveyor",
      description:
        "Streamline material handling with our conveyor systems. From bulk material transport to precise product handling, our conveyors offer reliable operation and customizable configurations to suit your specific requirements.",
      image: jawCrusherBg,
      link: "/product/conveyor",
    },
    {
      title: "Classifier",
      description:
        "Transform raw materials into high-quality sand with our sand plants. Engineered for efficiency and reliability, our sand plants deliver consistent output while minimizing environmental impact",
      image: jawCrusherBg,
      link: "/product/classifier",
    },
    {
      title: "Sand Plant",
      description:
        "Transform raw materials into high-quality sand with our sand plants. Engineered for efficiency and reliability, our sand plants deliver consistent output while minimizing environmental impact",
      image: sandPlantBg,
      link: "/product/sandPlant",
    },
    {
      title: "Screening",
      description:
        "Our screening solutions provide efficient separation of materials, ensuring precise grading and maximum productivity. From scalping to fine screening, our range of screens caters to diverse applications with ease",
      image: screeningBg,
      link: "/product/screening",
    },
    {
      title: "Vertical Shaft Impactor",
      description:
        "Elevate your crushing efficiency with our vertical shaft impactors. Designed for shaping and producing fine aggregates, our VSIs offer exceptional performance in tertiary crushing applications",
      image: visBg,
      link: "/product/verticalShaftImpacto",
    },
  ];




  return (
    <div className="w-11/12 lg:w-3/4 mx-auto mt-16 py-10 ">
      <div className="flex justify-center w-ful pt-4">
       <h1 className="text-zinc-900 text-3xl lg:text-4xl font-bold capitalize ">Popular Products</h1>
      </div>
      <Swiper 
      slidesPerView={1}
      spaceBetween={10}
      pagination={{
        clickable: true,
        // el: '.swiper-pagination'
        renderBullet: function (index, className) {
          return `<span class="${className} bg-orange-500"></span>`;
        },
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      modules={[Pagination]}
      className="mySwiper py-10">
      {products.map((product, index) => (
      <SwiperSlide key={index}>
       <ProductCard
        title={product.title}
        description={product.description}
        image={product.image}
        link={product.link}
      />
    </SwiperSlide>
  ))}
      </Swiper>
    </div>
  );
};

export default AllCard;
