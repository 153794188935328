import React from "react";
import ProductsDeatils from "../../components/product/ProductsDeatils";
import jawCrushersImage from "../../assets/Products/jawCrushers.png";
const JawCrushers = () => {
  const productInfo = {
    title: "Jaw Crushers",
    img: jawCrushersImage,
    description: {
      title: "Jaw Crushers",
      para: "Our jaw crushers are engineered to deliver exceptional performance in primary crushing applications. With robust construction and high crushing capacity, they ensure efficient reduction of even the toughest materials.",
    },
  };
  return (
    <div className="w-11/12 lg:w-3/4 mx-auto">
      <ProductsDeatils info={productInfo} />
    </div>
  );
};

export default JawCrushers;
