import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Navbar from "./layouts/nvabar/Navbar";
import Footer from "./layouts/Footer";
import "./style/index.css";
import About from "./pages/about/About";
import Gallery from "./pages/gallery/Gallery";
import Contact from "./pages/contact/Contact";
import JawCrushers from "./pages/products/JawCrushers";
import ConeCrusher from "./pages/products/ConeCrusher";
import Classifier from "./pages/products/Classifier";
import Conveyor from "./pages/products/Conveyor";
import SandPlant from "./pages/products/SandPlant";
import Screening from "./pages/products/Screening";
import VerticalShaftImpactor from "./pages/products/VerticalShaftImpactor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Helmet>
        <title>MDK Fabricators – Best Crushers & Solutions</title>
        <meta
          name="description"
          content="Experience the MDKF advantage and elevate your industrial operations with superior-quality crusher plants. Trust MDKF for reliable industrial solutions tailored to meet your needs."
        />
        <meta
          name="keywords"
          content="crusher plants, industrial solutions, MDKF advantage, Make in India, indigenous manufacturing, quality products, reliable solutions, innovative engineering, industrial operations"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />

        {/* all Products */}
        <Route path="/product/jawCrushers" element={<JawCrushers />} />
        <Route path="/product/coneCrusher" element={<ConeCrusher />} />
        <Route path="/product/classifier" element={<Classifier />} />
        <Route path="/product/conveyor" element={<Conveyor />} />
        <Route path="/product/sandPlant" element={<SandPlant />} />
        <Route path="/product/screening" element={<Screening />} />
        <Route
          path="/product/verticalShaftImpactor"
          element={<VerticalShaftImpactor />}
        />
      </Routes>
      <Footer />
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
