import React from "react";
import CompanyInfoItem from "./CompanyInfoItem";

const CompanyInfo = () => {
  return (
    <div className="px-4 lg:px-0">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-14">
          <CompanyInfoItem
            title={"No.1"}
            subTitle={"Best & Trusted Crusher "}
            para={"for crushing and screening equipment plant manufacture"}
          />
          <CompanyInfoItem
            title={"No.1"}
            subTitle={"Facilities"}
            para={"facilities"}
          />
           <CompanyInfoItem
            title={"35+"}
            subTitle={""}
            para={"Plant installations"}
          />
           <CompanyInfoItem
            title={"300+"}
            subTitle={""}
            para={"Employees"}
          />
        </div>
        
      </div>
    </div>
  );
};

export default CompanyInfo;
