import React from "react";
import ProductsDeatils from "../../components/product/ProductsDeatils";
import coneCrushersImage from "../../assets/Products/coneCrusher.png";
const ConeCrusher = () => {
  const productInfo = {
    title: "Cone Crusher",
    img: coneCrushersImage,
    description: {
      title: "Cone Crusher",
      para: "Experience superior crushing with our cone crushers. Engineered for versatility and reliability, our cone crushers excel in secondary and tertiary crushing, offering precise particle size control and exceptional throughput.",
    },
  };
  return (
    <div className="w-11/12 lg:w-3/4 mx-auto">
      <ProductsDeatils info={productInfo} />
    </div>
  );
};

export default ConeCrusher;
