import React from 'react'
import ProductsDeatils from '../../components/product/ProductsDeatils';
import jawCrushersImage from "../../assets/Products/jawCrushers.png";
const Classifier = () => {
  const productInfo = {
    title: "Classifer",
    img: jawCrushersImage,
    description: {
      title: "Classifer",
      para: "Achieve precise particle classification with our classifiers. Engineered for accuracy and reliability, our classifiers ensure efficient separation of materials, enhancing product quality and process efficiency",
    },
  };
  return (
    <div className="w-11/12 lg:w-3/4 mx-auto">
    <ProductsDeatils info={productInfo} />
  </div>
  )
}

export default Classifier
