import React from "react";
import callIcon from "../assets/contact/call.png";
import emailIcon from "../assets/contact/email.png";
import mapIcon from "../assets/contact/map.png";
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-5">

      <div className="bg-orange-50 py-5 ">
        <div className=" w-11/12 lg:w-3/4 mx-auto">
          <div className="flex flex-col lg:flex-row justify-between lg:items-center  gap-4 lg:gap-0 py-5">
            <div className=" w-full lg:w-[70%]">
              <h1 className="text-4xl text-orange-500 font-bold py-4">
                View our expansive machinery
              </h1>
              <p className="text-base pt-2 text-zinc-600 opacity-80">
                With MDKF, you have the chance to work with the brightest
                professionals in the field and be a part of our expanding global
                footprint. We are looking for motivated professionals to join
                our team.
              </p>
            </div>
            <button
              className="bg-orange-500 text-white h-10 w-[50%] lg:w-[20%] 
               rounded-md hover:bg-orange-600 transition-all"
            >
              Download Brochure
            </button>
          </div>
        </div>
      </div>

      <div className="bg-zinc-100 py-5">
        <div className="w-11/12 lg:w-3/4 mx-auto py-2 ">
          <div className=" flex flex-col lg:flex-row justify-between lg:items-center gap-6 lg:gap-0 ">
            <div className="flex items-center w-full lg:w-[30%] gap-3">
              <div>
                <img className=" w-8 lg:w-12" src={callIcon} alt="callIcon" />
              </div>
              <div className="flex flex-col lg:justify-center ">
                <span className="text-base text-zinc-600">Get in Touch</span>
                <span
                  className="text-xl font-semibold cursor-pointer transition-all border-b-2 border-transparent
                 hover:border-black pb-[0.05rem]"
                >
                  +91 6290 704 044
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3 w-full lg:w-[30%]">
              <div>
                <img className=" w-8 lg:w-12" src={emailIcon} alt="emailIcon" />
              </div>
              <div className="flex flex-col justify-center">
                <span className="text-base text-zinc-600">
                  For any queries mail us
                </span>
                <span
                  class="text-xl font-semibold cursor-pointer transition-all border-b-2 border-transparent
                 hover:border-black pb-[0.05rem]"
                >
                  support@mdkf.in
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3 w-full lg:w-[30%] ">
              <div>
                <img className=" w-8 lg:w-12" src={mapIcon} alt="mapIcon" />
              </div>
              <div className="flex flex-col justify-center w-full">
                <span className="text-xl  font-semibold text-zinc-900">
                  MDKF
                </span>
                <span className=" w-full text-base text-zinc-600  flex flex-col gap-1 ">
                  <span className="text-sm">
                    <span className="font-semibold"> Office : </span>
                    41/20, Makardah Road Howrah-711101
                  </span>
                 <span className="text-sm">
                 <span className="font-semibold">Factory : </span>
                  Vill:Nimerhati, P.O-Makardah, P.S-Domjur, Howrah-711409
                 </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-2">
        <div className="w-11/12 lg:w-3/4 mx-auto">
          <div className="flex flex-col lg:flex-row justify-start items-center py-2 gap-3 lg:gap-0">
            <div className="lg:w-[50%]">
              <span className="text-sm font-semibold text-zinc-900">
                {" "}
                &copy; 2024 mdkf | All rights reserved.
              </span>
            </div>
            <div className="flex items-center gap-3">
              <NavLink target="_blank"  className="w-8 h-8 rounded-full flex justify-center items-center bg-orange-500 cursor-pointer">
                <span className="text-white text-lg">
                  <FaFacebookF />
                </span>
              </NavLink>
              <NavLink target="_blank" to={"https://www.instagram.com/_m.d.k.f_/"} className="w-8 h-8 rounded-full flex justify-center items-center bg-orange-500 cursor-pointer">
                <span className="text-white text-lg">
                  <FaInstagram />
                </span>
              </NavLink>
              <NavLink target="_blank" to={""} className="w-8 h-8 rounded-full flex justify-center items-center bg-orange-500 cursor-pointer">
                <span className="text-white text-lg">
                  <FaWhatsapp />
                </span>
              </NavLink>
              <NavLink target="_blank" to={""} className="w-8 h-8 rounded-full flex justify-center items-center bg-orange-500 cursor-pointer">
                <span className="text-white text-lg">
                  <FaYoutube />
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
