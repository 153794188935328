import React from 'react'
import ProductsDeatils from '../../components/product/ProductsDeatils';
import sandPlantImage from "../../assets/Products/sandPlant.png";
const SandPlant = () => {
  const productInfo = {
    title: "Sand Plant",
    img: sandPlantImage,
    description: {
      title: "Sand Plant",
      para: "Transform raw materials into high-quality sand with our sand plants. Engineered for efficiency and reliability, our sand plants deliver consistent output while minimizing environmental impact",
    },
  };
  return (
    <div className="w-11/12 lg:w-3/4  mx-auto">
    <ProductsDeatils info={productInfo} />
  </div>
  )
}

export default SandPlant
