import React from 'react'
import ProductsDeatils from '../../components/product/ProductsDeatils';
import screeningImage from "../../assets/Products/screening.png";
const Screening = () => {
  const productInfo = {
    title: "Screening",
    img: screeningImage,
    description: {
      title: "Screening",
      para: "Our screening solutions provide efficient separation of materials, ensuring precise grading and maximum productivity. From scalping to fine screening, our range of screens caters to diverse applications with ease",
    },
  };
  return (
    <div className="w-11/12 lg:w-3/4 mx-auto">
      <ProductsDeatils info={productInfo} />
    </div>
  )
}

export default Screening
