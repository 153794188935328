export const links = [
    {
      name: "Products",
      submenu: true,
      sublinks: [
        {
          Head: "Crushers",
          sublink: [
            { name: "Jaw Crushers", link: "/product/jawCrushers" },
            { name: "Cone Crushers", link: "/product/coneCrusher" },
            // { name: "Fixed Shaft Cone Crusher", link: "/" },
            { name: "Vertical Shaft Impact Crushers", link: "/product/verticalShaftImpactor" },
            { name: "Screening", link: "/product/screening" },
            { name: "Classifier", link: "/product/classifier" },
            { name: "Conveyor", link: "/product/conveyor" },
            { name: "SandPlant", link: "/product/sandPlant" },
            { name: "Screening", link: "/product/screening" },
          ],
        },
        {
          Head: "Feeders & Screens",
          sublink: [
            { name: "Grizzly Feeder", link: "/" },
            { name: "Grizzly Screen", link: "/" },
            { name: "Vibrating Screen", link: "/" },
            { name: "High Speed Screen", link: "/" },
            { name: "P-Tech Screen", link: "/" },
          ],
        },
        {
          Head: "Classifiers",
          sublink: [
            { name: "Dry Classifier", link: "/" },
            { name: "Bucket Wheel Type Classifier", link: "/" },
            { name: "Vibrating Screen", link: "/" },
            { name: "Hydro Cyclone Classifier", link: "/" },
            { name: "Thickner", link: "/" },
          ],
         
        },
  

        {
            Head: "Semi Mobile",
            sublink: [
              { name: "Speed Bharath Primary -PSBJ", link: "/" },
              { name: "Speed Bharath Secondary-PSBC", link: "/" },
              { name: "Speed Bharath Tertiary-PSBV", link: "/" },
            ],
          },
          {
            Head: "Mining",
            sublink: [
              { name: "Cone Crusher H-Type", link: "/" },
              { name: "Feeder Breker", link: "/" },
              { name: "Sizer", link: "/" },
              { name: "Surface Miner", link: "/" },
              { name: "Jaw Crusher", link: "/" },
            ],
          },

          {
            Head: "Waster Procesing -C&d Waster, Bio Waster",
            sublink: [
              { name: "Jaw Crusher", link: "/" },
              { name: "Classifiers", link: "/" },
              { name: "Cone Crusher H-Type", link: "/" },
              { name: "Mobile Screen PTSG Series", link: "/" },
  
            ],
          },
      
          
      ],
    },
    {
      name: "Products line 2",
      submenu: true,
      sublinks: [
        {
          Head: "Engineering workers",
          sublink: [
            { name: "Bridges & Gireders", link: "/" },
            { name: "H-Beam Sleepers", link: "/" },
            // { name: "Fixed Shaft Cone Crusher", link: "/" },
            { name: "Sheet Metal", link: "/" },
            { name: "Pre Engineerd Building", link: "/" },
            { name: "Buckets hardfacing", link: "/" },
          ],
        },

        {
          Head: "Machine Works",
          sublink: [
            { name: "Supplying all Earth moving & road construction equipments spares", link: "/" },
            { name: "Providing Service  for all kinds of earth moving & Road construction equipments", link: "/" },
            // { name: "Fixed Shaft Cone Crusher", link: "/" },
            { name: "Supplying all kinds of Rock breaker spares", link: "/" },
          ],
        },
 

      ]
    }

  ];