import React from 'react'
import AboutHeader from '../../components/header.jsx/AboutHeader'
import CompnayOverview from '../../components/aboutCom/CompnayOverview'
import CompanyMoreInfo from '../../components/aboutCom/CompanyMoreInfo'
import OurStrengths from '../../components/aboutCom/OurStrengths'
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    <div className=''>
        <Helmet>
        <title>About - MDKF</title>
        <meta
          name="description"
          content="Established in 2000 and headquartered in Howrah, West Bengal, India,
          MDKF is a premier crusher plant manufacturing company. Committed to
          promoting indigenous manufacturing, we proudly manufacture our own
          products and have installed over 25 plants nationwide. At MDKF, we
          prioritize delivering top-notch quality at the best possible price,
          ensuring maximum value for our clients without compromising on
          excellence."
        />
      </Helmet>
      <AboutHeader/>
      <CompnayOverview/>
      <CompanyMoreInfo/>
      <OurStrengths/>
    </div>
  )
}

export default About
