import React, { useRef } from "react";
import indiaMap from "../../assets/contact/indiaMap.png";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { MdPrecisionManufacturing } from "react-icons/md";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";
import Img from "../lazyload/Img";
const ContactSection = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qg9gxn7', 'template_0t6gwbl', form.current, {
        publicKey: 'NlL0Hl44wpPMsC013',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          toast.success('Message Send!', {
            position: "top-right",
            autoClose: 5000,
            });
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error(`Message Not Send! ${error.text}`, {
            position: "top-right",
            autoClose: 5000,
            });
        },
      );
  };

  return (
    <div className="w-11/12 lg:w-3/4 mx-auto py-8">
      <div className="flex flex-col lg:flex-row gap-10 pt-5 ">
        <div className="flex-1">
          <h2 className="text-3xl font-semibold mb-6">Contact Us</h2>
          <form
           ref={form}
           onSubmit={sendEmail}  
           className="flex flex-col justify-center gap-6" action="">
            <input type="text" placeholder="Your Name" name="name" className="input" />
            <input
              type="text"
              placeholder="Your Designation"
              className="input"
              name="designation"
            />
            <input type="text" placeholder="Your Company" name="company" className="input" />
            <input type="email" placeholder="Your Email" name="email" className="input" />
            <input type="tel" placeholder="Your Phone" name="phone" className="input" />
            <input type="text" placeholder="Your Subject" name="subject" className="input" />
            <textarea
              placeholder="Your Message"
              className="input"
              rows="4"
              name="message"
            ></textarea>
            <button className="bg-orange-500 hover:bg-orange-600  w-[50%]  text-white font-bold py-3 px-6 rounded-md shadow-lg transition duration-300">
              Send Message
            </button>
          </form>
        </div>
        <div className="flex-1 relative">
          <div className="flex justify-center">
            <h2 className="text-3xl font-semibold ">Our Network</h2>
          </div>

          <div className="flex justify-center items-center py-3">
            <Img
              className="w-[100%] lg:w-[80%] "
              src={indiaMap}
              alt="India Map"
            />
          </div>

          <div className="absolute -bottom-10 right-0 md:bottom-24 md:right-7 lg:bottom-24 lg:right-5">
            <div className="flex  items-center gap-2 text-lg">
              <span className="text-orange-500">
                <HiBuildingOffice2 />
              </span>
              <span className="text-zinc-700">Head Office</span>
            </div>
            <div className="flex  items-center gap-2 text-lg">
              <span className="text-blue-500">
                <HiOutlineBuildingOffice />
              </span>
              <span className="text-zinc-700">Branch Office</span>
            </div>

            <div className="flex  items-center gap-2 text-lg">
              <span className="text-red-500">
                <MdPrecisionManufacturing />
              </span>
              <span className="text-zinc-700">Manufacturing Unit</span>
            </div>
            <div className="flex  items-center gap-2 text-lg">
              <span className="text-purple-500">
                <FaHandshakeSimple />
              </span>
              <span className="text-zinc-700">Dealer</span>
            </div>
            <div className="flex  items-center gap-2 text-lg">
              <span className="text-green-500">
                <FaUsers />
              </span>
              <span className="text-zinc-700">Core Sales Team</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
