import React from "react";
import { Helmet } from "react-helmet";
const ProductsDeatils = ({ info }) => {
  return (
    <div className="py-4 mt-24">
       <Helmet>
        <title>{info?.title} - Product Details - MDKF</title>
        <meta
          name="description"
          content={info?.description?.para}
        />
      </Helmet>
      <div className="flex flex-col items-center  justify-center">
        <h1 className="text-4xl text-orange-500 font-semibold py-4">
          {info?.title}
        </h1>
        <div className="w-[70%] lg:w-[30%] py-4">
          <img
            className="w-full object-cover"
            src={info?.img}
            alt={info?.title}
          />
        </div>
      </div>

      <div className="">
        <h1 className="text-zinc-900 text-3xl lg:text-5xl font-bold  pb-2 lg:pb-6">
          {info?.description?.title}
        </h1>
        <p className="text-base lg:text-lg text-zinc-500">{info?.description.para}</p>
      </div>
    </div>
  );
};

export default ProductsDeatils;
