

import React from "react";
import overview1 from "../../assets/about/overview1.jpg";
import overview2 from "../../assets/about/overview2.jpg";
import Img from "../lazyload/Img";

const CompanyOverview = () => {
  return (
    <div className="w-11/12 lg:w-3/4 mx-auto">
      {/* First Section */}
      
      <div className="flex flex-col md:flex-row gap-8 py-12 items-center">
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-6">About Us</h1>
          <p className="text-lg leading-relaxed">
            Established in 2000 and headquartered in Howrah, West Bengal, India,
            MDKF is a premier crusher plant manufacturing company. Committed to
            promoting indigenous manufacturing, we proudly manufacture our own
            products and have installed over 25 plants nationwide. At MDKF, we
            prioritize delivering top-notch quality at the best possible price,
            ensuring maximum value for our clients without compromising on
            excellence.
          </p>
        </div>
        <div className="md:w-1/2">
          <Img
            src={overview1}
            alt="aboutus"
            className="max-w-full h-auto rounded-md shadow-lg"
          />
        </div>
      </div>

      {/* Second Section */}
      <div className="flex flex-col md:flex-row gap-8 py-12 items-center">
        <div className="md:w-1/2 order-2 md:order-1">
          <Img
            src={overview2}
            alt="aboutus"
            className="max-w-full h-auto rounded-md shadow-lg"
          />
        </div>
        <div className="md:w-1/2 order-1 md:order-2">
          <h1 className="text-4xl font-bold mb-6">Why Choose MDKF</h1>
          <p className="text-lg leading-relaxed">
            Experience the MDKF advantage and elevate your industrial operations
            to new heights. With a steadfast commitment to bolstering indigenous
            manufacturing, we set the benchmark for quality and reliability. Our
            track record of over 25 successfully installed plants nationwide
            speaks volumes about our dedication to excellence. Trust MDKF for
            superior quality, competitive prices, and unparalleled value in
            industrial solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;

