import React from 'react'
import ContactHeader from '../../components/header.jsx/ContactHeader'
import ContactSection from '../../components/contactSection/ContactSection'
import { Helmet } from 'react-helmet'

const Contact = () => {
  return (
    <div >
       <Helmet>
        <title>Contact Us - MDKF</title>
        <meta
          name="description"
          content="Get in touch with MDKF. Fill out the contact form to reach us regarding any inquiries, collaborations, or questions. Our team will get back to you promptly."
          
        />
      </Helmet>
    <ContactHeader/>
    <ContactSection/>
    </div>
  )
}

export default Contact
