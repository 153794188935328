import React, { useEffect, useRef } from "react";

const OurStrengths = () => {


    const OurStrength=[
        "R&D Focused",
        "Engineering Excellence",
        "ESG Driven",
        "Integrated Facilities",
        "Skilled Workforce",
        "Customer-Centric Approach"
    ]

    const scrollMenuRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (scrollMenuRef.current) {
                scrollMenuRef.current.scrollToNext();
            }
        }, 3000); // Adjust the interval (in milliseconds) as needed

        return () => clearInterval(interval);
    }, []);


    // const menuItems = 
  return (
    <div className="py-4 w-11/12 lg:w-3/4 mx-auto">
      <div className="w-full py-2">
        <div className="flex flex-col items-center">

          <h1 className="text-4xl font-semibold uppercase text-gray-900 mb-8">
            Our Strengths
          </h1>
          <p className="text-lg leading-relaxed text-gray-700 max-w-3xl">
            With over two decades of experience and expertise in design,
            metallurgy, fabrication, machining, and infrastructure, MDKF stands as
            a trusted name in the industry. Our strength lies in our dedicated
            team of qualified engineers and skilled labor, ensuring unmatched
            quality and reliability in every project.
          </p>
        </div>
       
      </div>
      
      <div className="flex justify-start overflow-hidden py-8 my-7 ">
      {
         OurStrength.map((char, index) => (
            <h2 
            style={{ textShadow: '0px 0px 1px rgba(0,0,0,0.3)' }}
            className="all-strength text-zinc-800 font-thin text-lg px-24 text-nowrap  " key={index}>{char}</h2>
          ))
     }
    </div>

    </div>
  );
};

export default OurStrengths;
