import React from 'react'
import overview1 from "../../assets/about/overview1.jpg";
import { Link } from 'react-router-dom';
import hd1 from "../../assets/hdImage/hd1.jpg"
import Img from '../lazyload/Img';
const HomeSection1 = () => {
  return (
  
    <div className='w-11/12 lg:w-3/4 mx-auto mt-16'>
  <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
    <div className='bg-white h-[100%] rounded-lg shadow-md overflow-hidden'>
      <Img className='w-full  h-96  object-cover' src={overview1} alt="Product Overview 1" />
      <div className='p-6'>
        <h1 className='text-xl font-bold text-gray-900 mb-2'>Products</h1>
        <p className='text-gray-700 mb-4'>Indigenously designed Crushers, Screens, Pavers, Classifiers, and more heavy machinery for all types of earthworks.</p>
        <Link to="/product/jawCrushers" className='text-orange-500 font-semibold hover:underline'>View all products</Link>
      </div>
    </div>
    <div className='bg-white h-[100%] rounded-lg shadow-md overflow-hidden'>
      <Img className='w-full  h-96 object-cover' src={hd1} alt="Product Overview 2" />
      <div className='p-6'>
        <h1 className='text-xl font-bold text-gray-900 mb-2'>Applications</h1>
        <p className='text-gray-700 mb-4'>High levels of performance and innovative details, simple handling and maximum safety for the operator</p>
        <Link to="/" className='text-orange-500 font-semibold hover:underline'>View all applications</Link>
      </div>
    </div>
  </div>
</div>




  )
}

export default HomeSection1
