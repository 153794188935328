import React from 'react'
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import mapData from "../../config/mapData.json"
import CompanyInfo from './CompanyInfo';
const WorldMap = () => {
  return (
    <div className='relative' >
     <div className='flex flex-col lg:flex-row items-center  lg:justify-center   ' >
    <div className='w-[100%] h-[100%] lg:w-[60%] lg:h-[60%]'>
    <ComposableMap>
      <Geographies
       geography={mapData}
       >
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} 
            style={{
                default: { fill: "#D6D6DA" }, // Change this color to your desired fill color
                hover: { fill: "#F53", outline: "none", cursor:"pointer" },
                pressed: { fill: "#E42" },
              }}
            />
          ))
        }
      </Geographies>
    </ComposableMap>
    </div>
    <div className=' lg:absolute  lg:-bottom-[10%] lg:left-[15%]  flex items-center lg:justify-center '>
     <CompanyInfo/>
     </div>
     </div>

    
   
    </div>
  )
}

export default WorldMap
