import React from 'react'
import GalleryHeader from '../../components/header.jsx/GalleryHeader'
import GallerySection from '../../components/gallerySection/GallerySection'
import { Helmet } from 'react-helmet'

const Gallery = () => {
  return (
    <div>
       <Helmet>
        <title>Gallery - MDKF</title>
        <meta
          name="description"
          content="Explore the stunning gallery of MDKF featuring breathtaking images and visuals."
        />
      </Helmet>
     <GalleryHeader/>
     <GallerySection/>
     
    </div>
  )
}

export default Gallery
