import React from 'react'
import aboutCover from "../../assets/aboutCover.jpg"
import Img from '../lazyload/Img'
const AboutHeader = () => {
  return (
    <div className='about header '>
      <div className='w-full h-[40vh] relative'>
      <img className='w-full h-full object-cover' src={aboutCover} alt="aboutCover" />
      <div className='absolute top-0 left-0 w-full h-full bg-black opacity-50'></div>
    
      <div className='absolute top-[50%] left-[50%]  transform -translate-x-1/2 -translate-y-1/2'>
        <h1 className='text-4xl text-white font-bold text-center '>ABOUT US</h1>
        <p className='text-white opacity-80 text-center text-lg'>Delivering Excellence</p>
      </div>
      </div>
    </div>
  )
}

export default AboutHeader
