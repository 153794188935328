import React, { useEffect, useRef, useState } from 'react'
import HomeSection1 from '../../components/homeCom/HomeSection1'
import WorldMap from '../../components/homeCom/WorldMap'
import AllCard from '../../components/homeCom/AllCard'
import heroBgDesktop from "../../assets/hero.jpg"
import heroBgMobile from "../../assets/about/overviewMobile.jpg"
import Img from '../../components/lazyload/Img'
import HomeSection2 from '../../components/homeCom/HomeSection2'
const Home = () => {
  const videoRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  console.log(backgroundImage)

  useEffect(() => {
    // Ensure the video is playing and looped when it's loaded
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.loop = true;
    }

    // Call the function to set background initially and on resize
    setBackgroundBasedOnWindowSize();

    window.addEventListener('resize', setBackgroundBasedOnWindowSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', setBackgroundBasedOnWindowSize);
    };
  }, []);

  // Function to set background based on window size
  const setBackgroundBasedOnWindowSize = () => {
    const isMobile = window.innerWidth < 867; // Assuming mobile width is below 768px
    console.log("isMObile", window.innerWidth)
    const newBackgroundImage = isMobile ? heroBgMobile : heroBgDesktop;

    // Set background image dynamically using state
    setBackgroundImage(newBackgroundImage);
  };
  return (
    <div>
      <div className="heroBanner ">
      <div className="backdrop-img ">
          <Img src={backgroundImage}  alt='herobg'/>
        </div>
      
      {/* <div className="opacity-layout"></div> */}
      <div className='w-3/4 mx-auto'>
        <div className="heroBannerContent">
          {/* <span className="hero-title">MDK Fabricators </span>
          <span className="hero-subTitle">
          Best & Trusted Crusher
          for crushing and screening equipment plant manufacture
          </span> */}
          
        </div>
      </div>
      </div>

    <HomeSection1/>
   <div className='w-11/12 lg:w-3/4 mx-auto'>
    <WorldMap/>
    
   </div>

   <AllCard/>
   <HomeSection2/>
  

    </div>
  )
}

export default Home
