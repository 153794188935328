
import React from "react";
import { TbTargetArrow } from "react-icons/tb";
import { IoTelescopeOutline } from "react-icons/io5";

const CompanyMoreInfo = () => {
  return (
    <div className="w-11/12 mx-auto lg:w-full lg:mx-0 flex flex-col lg:flex-row gap-10">
      <div className="w-full lg:w-[70%] py-8 bg-orange-100 rounded-lg">
        <div className="w-11/12 lg:w-3/5 mx-auto">
          <h1 className="text-orange-600 text-3xl mb-4 font-semibold flex items-center gap-2">
            <span><TbTargetArrow/></span>
             <span>Our Mission</span>
             
          </h1>
          <p className="text-gray-800 text-base leading-relaxed">
            Our mission at MDKF is to lead the charge in manufacturing
            superior-quality crusher plants, tailored to meet the evolving needs
            of businesses. With a steadfast commitment to Make in India, we aim
            to empower industries with innovative engineering solutions.
          </p>
        </div>
      </div>
      <div className=" w-full lg:w-[30%] py-8 bg-orange-100 rounded-lg">
        <div className="w-full lg:w-[90%] px-6">
          <h1 className="text-orange-600 text-3xl mb-4 font-semibold flex items-center gap-2">
            <span><IoTelescopeOutline/></span>
           <span> Our Vision</span>
          </h1>
          <p className="text-gray-800 text-base leading-relaxed">
            MDKF envisions revolutionizing the industrial landscape by providing
            cutting-edge crusher plant solutions, driving growth and progress
            across industries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyMoreInfo;

